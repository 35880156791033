import React from "react";
import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdMoney,
  MdNote,
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import Projects from "views/admin/project";
import Credit from "views/admin/credit"
import DataTables from "views/admin/dataTables";
import RTL from "views/admin/rtl";
import Success from "views/admin/Success";
// Auth Imports
import SignInCentered from "views/auth/signIn";
import SignUpCentered from "views/auth/signUp";
import Failure from "views/admin/Failure";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
  },
  // {
  //   name: "NFT Marketplace",
  //   layout: "/admin",
  //   path: "/nft-marketplace",
  //   icon: (
  //     <Icon
  //       as={MdOutlineShoppingCart}
  //       width='20px'
  //       height='20px'
  //       color='inherit'
  //     />
  //   ),
  //   component: NFTMarketplace,
  //   secondary: true,
  // },
  {
    name: "Upload Project",
    layout: "/admin",
    path: "/upload",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: Profile,
  },

  {
    name: "All Project",
    layout: "/admin",
    path: "/projects",
    icon: <Icon as={MdNote} width='20px' height='20px' color='inherit' />,
    component: Projects,
  },
  {
    name: "Buy Credits",
    layout: "/admin",
    path: "/credit",
    icon: <Icon as={MdMoney} width='20px' height='20px' color='inherit' />,
    component: Credit,
  },
  // {
  //   name: "Previous Publishes",
  //   layout: "/admin",
  //   icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
  //   path: "/data-tables",
  //   component: DataTables,
  // },

  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignInCentered,
  },
  {
    name: "Sign Up",
    layout: "/auth",
    path: "/sign-up",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignUpCentered,
  },
  // {
  //   name: "RTL Admin",
  //   layout: "/rtl",
  //   path: "/rtl-default",
  //   icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
  //   component: RTL,
  // },
  {
    name: "Payment",
    layout: "/admin",
    path: "/success",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: Failure,
  },
  {
    name: "Payment",
    layout: "/admin",
    path: "/failure",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: Success,
  },
];

export default routes;
