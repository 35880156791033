
import { Box, Grid } from "@chakra-ui/react";
import Projects from "views/admin/profile/components/Projects";
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/avatar4.png";
import React from "react";

export default function Overview() {
    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Grid
                mb='20px'
                gap={{ base: "20px", xl: "20px" }}>
                <Projects
                    banner={banner}
                    avatar={avatar}
                    name='Adela Parkson'
                    job='Product Designer'
                    posts='17'
                    followers='9.7k'
                    following='274'
                />
            </Grid>
        </Box>
    );
}
