// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import baseURL from "assets/common/baseUrl";
// Custom components
import Card from "components/card/Card.js";
import React, { useEffect, useState, CSSProperties } from "react";
// Assets
import { useHistory } from 'react-router-dom';
import { useToast } from '@chakra-ui/react'
import { MdUpload } from "react-icons/md";
import { userAtom } from "state";
import Dropzone from "views/admin/profile/components/Dropzone";
import { useAtom } from 'jotai';
import axios from 'axios'
import ClipLoader from "react-spinners/ClipLoader";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};
export default function Upload(props) {
const toast = useToast();
  const { used, total, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const brandColor = useColorModeValue("brand.500", "white");
  const textColorSecondary = "gray.400";
  const [selectedFile, setSelectedFile] = useState(null);
  const [user, setUser] = useAtom(userAtom);
  const history = useHistory();
  const handleFileChange = (event) => {
    // Assuming you want to allow only .glb files
    const fileInput = event.target;
    const file = fileInput.files[0];

    if (file && file.name.endsWith('.glb')) {
      console.log("FILE", file)
      setSelectedFile(file);
    } else {
      console.log("FILE", file)
      // Handle the case when an invalid file is selected
      setSelectedFile(null);
      alert('Please select a valid .glb file.');
    }
  };

  const handleUpload = () => {
    setLoading(true);
    // Add your logic to handle the file upload here
    if (selectedFile) {
      // Create a FormData object to send the file as part of the request
      const formData = new FormData();
      formData.append('file', selectedFile);
      console.log("USER", user)
      // Make a POST request to your backend using Axios
      axios.put(`${baseURL}users/addUrl/${user._id}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          setLoading(false);
          // Handle the response from the backend
          console.log('Upload successful:', response.data);
          setUser(response.data)
          toast({
            title: 'Project Uploaded Succesfully.',
            description: "We've created your game play link visit previous publishes.",
            status: 'success',
            duration: 9000,
            isClosable: true,
          })
          setTimeout(()=>{
            history.push('/admin/projects');
          },[5000])
        })
        .catch(error => {
          // Handle errors
          console.error('Error uploading file:', error);
        });
    } else {
      setLoading(false);
      // Handle the case when no file is selected
      alert('Please select a file before uploading.');
    }
  };
  useEffect(() => {
    const storedUser = localStorage.getItem('userId');
    console.log("ID", storedUser)
    if(storedUser){
      axios.get(`${baseURL}users/${storedUser}`)
      .then((res) => {
        if (res.status === 200) {
          setUser(res.data)
          console.log("RELOAD USER", res);
        }
      })
      .catch((error) => {
        console.log("Error posting user data:", error);
      });
    } 
  }, [])

  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#ffffff");

  if (loading) {

    return (
      <ClipLoader
        color={color}
        loading={loading}
        cssOverride={override}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    )
  }
  return (
    <Card {...rest} mb='20px' align='center' p='20px'>

      {/* <Dropzone
          w={{ base: "100%", "2xl": "268px" }}
          me='36px'
          maxH={{ base: "60%", lg: "50%", "2xl": "100%" }}
          minH={{ base: "60%", lg: "50%", "2xl": "100%" }}
         // onDrop={handleFileChange}
          content={
            <Box>
              <Icon as={MdUpload} w='80px' h='80px' color={brandColor} />
              <Flex justify='center' mx='auto' mb='12px'>
              <input type="file" onInput={(e)=> { console.log("HERE", e.target.value)}} accept=".glb" />
                <Text fontSize='xl' fontWeight='700' color={brandColor}>
                  Upload Files
                </Text>
              </Flex>
              <Text fontSize='sm' fontWeight='500' color='secondaryGray.500'>
                Only .glb  files are allowed
              </Text>
            </Box>
          }
        /> */}
      {user?.credit !== 0 ? (<Flex direction='column' pe='44px'>
        <Flex h='100%' direction={{ base: "column", "2xl": "row" }}>
          <input type="file" onInput={(e) => { console.log("HERE", e.target.value); handleFileChange(e) }} accept=".glb" />
          <Text
            color={textColorPrimary}
            fontWeight='bold'
            textAlign='start'
            fontSize='2xl'
            mt={{ base: "20px", "2xl": "50px" }}>
            Add your model gld file
          </Text>
          <Text
            color={textColorSecondary}
            fontSize='md'
            my={{ base: "auto", "2xl": "10px" }}
            // mx='auto'
            textAlign='start'>
            Upload your model file check in blender if it conatins all the textures, so that it looks asthetic..
          </Text>
          <Flex w='100%'>
            <Button
              onClick={handleUpload}
              me='100%'
              mb='50px'
              w='140px'
              minW='140px'
              mt={{ base: "20px", "2xl": "auto" }}
              variant='brand'
              fontWeight='500'>
              Publish now
            </Button>
          </Flex>
        </Flex>
      </Flex>) : (<Text
        color={textColorSecondary}
        fontSize='md'
        my={{ base: "auto", "2xl": "10px" }}
        // mx='auto'
        textAlign='start'>
        Buy More Credit to upload more than 1 Projects
      </Text>)}




    </Card>
  );
}
