
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useAtom } from 'jotai'
import { profileAtom } from "state";
import baseURL from "assets/common/baseUrl";
import { userIdAtom } from "state";
import { userAtom } from "state";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import LoginForm from "../LoginForm";
import SignUpForm from "../SignUpForm";

function SignUp() {
  // Chakra color mode

  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const [profile, setProfile] = useAtom(profileAtom);
  const [user, setUser] = useState([]);
  // const [profile, setProfile] = useState([]);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      console.log("codeResponse", codeResponse);
      setUser(codeResponse);
    },
    onError: (error) => console.log('Login Failed:', error)
  });
  const [userId, setUserId] = useAtom(userIdAtom);
  const [userdata, setUserData] = useAtom(userAtom);
  const history = useHistory();
  useEffect(() => {
    console.log("WHy not loading")
    if (user.length !== 0) {
      axios
        .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            Accept: 'application/json'
          }
        })
        .then((res) => {
          axios.post(`${baseURL}users/register`, res.data)
            .then((res) => {
              if (res.status === 200) {
                console.log(res);
                setUserId(res.data._id)
                setUserData(res.data)
                setProfile(res.data);
                localStorage.setItem('user', JSON.stringify(res.data));
                localStorage.setItem('userId', res.data._id);
                localStorage.setItem('token', JSON.stringify(user.access_token));
                // Additional success logic if needed
                history.push('/admin/default');
                window.location.reload();
              }
            })
            .catch((error) => {
              console.log("Error posting user data:", error);
            });
        })
        .catch((err) => {
          console.log("Error fetching user info:", err);
        });
    }
  }, [user]);

  return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>Sign Up || Deski-Saas & Software React Template</title>
      </Helmet>
      {/* End Page SEO Content */}
      <div className="user-data-page clearfix d-lg-flex">
        <div className="illustration-wrapper d-flex align-items-center justify-content-between flex-column">
          <h3 className="font-rubik">
            We have a “strategic” plan its <br /> called doing things.
          </h3>
          <div className="illustration-holder">
            <img
              src="images/assets/ils_08.svg"
              alt="illustration"
              className="illustration"
            />
            <img
              src="images/assets/ils_08.1.svg"
              alt="illustration"
              className="shapes shape-one"
            />
            <img
              src="images/assets/ils_08.2.svg"
              alt="illustration"
              className="shapes shape-two"
            />
          </div>
        </div>
        {/* /.illustration-wrapper  */}

        <div className="form-wrapper">
          <div className="d-flex justify-content-between">
            <div className="logo">
              <a href="https://www.gamefolio.in/">
                <img src="images/logo/game1.jpg" alt="logo" />
              </a>
            </div>
            <a className="font-rubik go-back-button" href="https://www.gamefolio.in/">
              Go to home
            </a>
          </div>
          {/* End d-flex */}
          <div className="mt-30">
            <h2>Join with thousands of Creators!</h2>
            <p className="header-info pt-30 pb-50">
              Already have an account? <Link to="/login">Login</Link>
            </p>
          </div>
          <SignUpForm />
          {/* End Signup Form */}
          <div className="header-info  pb-50 ">
            <Button
              className="header-info  pb-50"
              fontSize='sm'
              me='0px'
              mb='26px'
              py='15px'
              h='50px'
              borderRadius='16px'
              bg={googleBg}
              color={googleText}
              fontWeight='500'
              _hover={googleHover}
              _active={googleActive}
              _focus={googleActive}
              onClick={login}
            >
              <Icon as={FcGoogle} w='20px' h='20px' me='10px' />
              Sign up with Google
            </Button>
          </div>
          <p className="text-center font-rubik copyright-text">
            Copyright @{new Date().getFullYear()}{" "}
            <a
              // href="https://themeforest.net/user/ib-themes/portfolio"
              target="_blank"
              title="myFrame"
              rel="noreferrer"
            >
              Gamefolio Inc
            </a>{" "}
            inc.
          </p>
          {/* End .copyright */}
        </div>
        {/* /.form-wrapper */}
      </div>
      {/* /.user-data-page */}
    </div>
  );
}

export default SignUp;
