
import {

    Box,


    Icon,
    Select,
    SimpleGrid,
    Stack,
    useColorModeValue,
} from "@chakra-ui/react";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, { useEffect, useState } from "react";
import {
    MdAddTask,
    MdAttachMoney,
    MdBarChart,
    MdFileCopy,
} from "react-icons/md";
import { Input } from "@chakra-ui/react"
import { useAtom } from 'jotai';
import { userAtom } from "state";
import { Button, ButtonGroup } from "@chakra-ui/react"
import axios from 'axios';
import baseURL from "assets/common/baseUrl";
export default function Credits() {
    // Chakra Color Mode
    const [user, setUser] = useAtom(userAtom)
    console.log("USER", user)
    const brandColor = useColorModeValue("brand.500", "white");
    const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
        // Parse the JSON string into a JavaScript object
        const parsedUser = JSON.parse(storedUser);
        // Access the "id" property
        const userId = parsedUser.id;
        console.log("User ID:", userId);
    } else {
        console.log("No user data in local storage");
    }

    const handleCheckout = () => {
        const parsedUser = JSON.parse(storedUser);
        const userId = parsedUser.id;
        console.log({
            userId,
            credit
        })
        axios
            .post(`${baseURL}create-checkout-session`, {
                headers: {
                    //'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    // "authorization": JSON.parse(token)
                },
                userId,
                credit
            },)
            .then((response) => {
                if (response.data.url) {
                    window.location.href = response.data.url;
                }
            })
            .catch((err) => console.log(err));
    };
    useEffect(() => {
        const storedUser = localStorage.getItem('userId');
        console.log("ID", storedUser)
        axios.get(`${baseURL}users/${storedUser}`)
            .then((res) => {
                if (res.status === 200) {
                    setUser(res.data)
                    console.log("RELOAD USER", res);
                }
            })
            .catch((error) => {
                console.log("Error posting user data:", error);
            });

    }, [])
    const [credit, setCredit] = useState(0);
    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <SimpleGrid
                columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
                gap='20px'
                mb='20px'>
                <MiniStatistics
                    startContent={
                        <IconBox
                            w='56px'
                            h='56px'
                            bg={boxBg}
                            icon={
                                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
                            }
                        />
                    }
                    name='Credits'
                    value={user?.credit}
                />
                <MiniStatistics
                    startContent={
                        <IconBox
                            w='56px'
                            h='56px'
                            bg={boxBg}
                            icon={
                                <Icon w='32px' h='32px' as={MdFileCopy} color={brandColor} />
                            }
                        />
                    }
                    name='Price of 1 Credit'
                    value="1 $"
                />
                <MiniStatistics
                    startContent={
                        <IconBox
                            w='56px'
                            h='56px'
                            bg={boxBg}
                            icon={
                                <Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />
                            }
                        />
                    }
                    name='Your Amount'
                    value={parseInt(user?.credit) * 84}
                />
            </SimpleGrid>
            <Stack spacing={4} direction="row" align="center">
                <MiniStatistics
                    startContent={
                        <Box w="150px" h="40px" >

                            <Input onChange={(e) => { console.log(e.target.value); setCredit(e.target.value) }} placeholder=" Enter Credit" borderRadius="16px" />
                        </Box>
                    }
                    name='Price'
                    value={parseInt(credit) * 84}

                />
                <Button onClick={handleCheckout} variant="brand" size="lg">
                    Buy
                </Button>
            </Stack>


        </Box>
    );
}
