
import {
    Box,
    SimpleGrid,

} from "@chakra-ui/react";

import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
} from "@chakra-ui/react"

export default function Failure() {

    return (

        <Alert
            status="success"
            variant="subtle"
            flexDirection="column"
            justifyContent="center"
            textAlign="center"
            height="100vh" // Set the height to cover the entire viewport
        >
            <AlertIcon boxSize="40px" mr={0} />
            <AlertTitle mt={4} mb={1} fontSize="lg">
                Payment Successful
            </AlertTitle>
            <AlertDescription maxWidth="sm">
                Thank you for your payment. Your transaction was successful! 
                Your Credits are added
            </AlertDescription>
        </Alert>

    );
}
