
import {
    Box,
    SimpleGrid,

} from "@chakra-ui/react";

import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
} from "@chakra-ui/react"

export default function Success() {

    return (
     
                <Alert
                    status="error"
                    variant="subtle"
                    flexDirection="column"
                    justifyContent="center"
                    textAlign="center"
                    height="100vh" // Set the height to cover the entire viewport
                >
                    <AlertIcon boxSize="40px" mr={0} />
                    <AlertTitle mt={4} mb={1} fontSize="lg">
                        Payment Not Successful
                    </AlertTitle>
                    <AlertDescription maxWidth="sm">
                        We're sorry, but the payment was not successful. Please try again later.
                    </AlertDescription>
                </Alert>
      
    );
}
