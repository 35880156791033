import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/admin';
import RtlLayout from 'layouts/rtl';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import { GoogleOAuthProvider } from '@react-oauth/google';
import "./assets/main.scss";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SignUp from 'views/auth/signUp';
import Success from 'views/admin/Success';
import Failure from 'views/admin/Failure';
ReactDOM.render(
	<GoogleOAuthProvider clientId="417970531436-2vrib1mi4pcmm2rmmierhur548b494uf.apps.googleusercontent.com">
		<ToastContainer/>
	<ChakraProvider theme={theme}>
		<React.StrictMode>
			<ThemeEditorProvider>
				<HashRouter>
					<Switch>
						<Route path={`/auth`} component={AuthLayout} />
						<Route path={`/auth`} component={AuthLayout} />
						<Route path={`/sign-up`} component={SignUp} />
						<Route path={`/payment/success`} component={Success} />
						<Route path={`/payment/failed`} component={Failure} />
						<Route path={`/admin`} component={AdminLayout} />
						<Route path={`/rtl`} component={RtlLayout} />
						<Redirect from='/' to='/admin' />
					</Switch>
				</HashRouter>
			</ThemeEditorProvider>
		</React.StrictMode>
	</ChakraProvider>,
	</GoogleOAuthProvider>,
	document.getElementById('root')
);
