

let baseURL = 'https://gamefolio-backend.onrender.com/api/v1/'
//let baseURL = 'https://goldfish-app-neiqb.ondigitalocean.app/api/v1/'
//let baseURL = 'https://trading-sathi-backend.onrender.com/api/v1/'
/*{Platform.OS == 'android'
? baseURL = 'http://192.168.1.2:5000/api/v1/'
: baseURL = 'http://localhost:5000/api/v1/'
}*/

export default baseURL;